import axios from 'axios'

import { useAuth } from '@/composables/auth.js'

const axiosHttp = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 30000,
})

axiosHttp.interceptors.request.use(
  async config => {
    try {
      const auth = useAuth()
      const token = await auth.getAccessToken()
      config.headers['Authorization'] = `Bearer ${token}`
    } catch (err) {
      console.error('Authorization failed')
      console.error(err)
    }
    return config
  },
  error => Promise.reject(error)
)

export default axiosHttp
